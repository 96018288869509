<template>
	<div class="trialdrive">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="上传驾驶证"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		<!-- 描述 -->
		<div class="trialdrive__init">
			请拍摄客户驾驶证的正反面并上传，确保拍摄完整与清晰
		</div>
		
		<!-- 正面 -->
		
		<div class="front">
			<van-uploader accept="image/png,image/jpeg,image/gif" :before-read="before" :after-read="uploadavatarfront">
				<div class="front__con" v-if="front_photo != ''">
					<van-image class="front__con__img" :src="front_photo" fit="contain" />
					<div class="front__con__edit">
						<img src="@/assets/trial/icon_uploadimg.png">
						<div>上传驾驶证主页</div>
					</div>
				</div>
				<div class="front__btn" v-if="front_photo == ''">
					<img src="@/assets/trial/icon_uploadimg.png">
					<div>上传驾驶证主页</div>
				</div>
		</van-uploader>
			</div>
		<!-- 反面 -->
		
		<div class="back">
			<van-uploader accept="image/png,image/jpeg,image/gif" :before-read="before" :after-read="uploadavatarback">
				<div class="back__con" v-if="back_photo != ''">
					<van-image class="back__con__img" :src="back_photo" fit="contain" />
					<div class="back__con__edit">
						<img src="@/assets/trial/icon_uploadimg.png">
						<div>上传驾驶证副页</div>
					</div>
				</div>
				<div class="back__btn" v-if="back_photo == ''">
					<img src="@/assets/trial/icon_uploadimg.png">
					<div>上传驾驶证副页</div>
				</div>
			</van-uploader>
		</div>

		<!-- 保存 -->
		<div style="height: 144px;"></div>
		<div class="savebtn">
			<div @click="handSave">保存</div>
		</div>
		<van-loading v-if="loading" class="loading" size="44px" type="spinner" vertical>正在上传...</van-loading>
	</div>
</template>

<script>
export default {
	components: {
	},
	data() {
		return {
			loading:false,
			form: {
				number: '',
				front_photo: '',
				back_photo: '',
			},
			imgType: '',
			front_photo: '',
			back_photo: '',
		};
	},
	mounted() {
		if (this.$route.query.number) this.form.number = this.$route.query.number
		this.getAgreementInfo()
	},
	methods: {
		// 判断类型
		before(file,detail){
			this.loading = true
				if(file.type.slice(0,5) == 'image'){
					return true
				}else{
					this.$toast.fail('请上传图片文件')
				}
			},
		// 上传正面
		async uploadavatarfront(e){
			console.log('11111')
			let formData = new FormData();//通过formdata上传
			formData.append('file', e.file)
			const {data:res} = await this.$http.post('/api/alioss/upload',formData)
			if(res.code == 1){
				this.loading = false
				this.form.front_photo = res.data.res.url
				this.front_photo = res.data.res.fullurl
				if (this.imgType == 'back') {
					this.form.back_photo = res.data.res.url
					this.back_photo = res.data.res.fullurl + '?x-oss-process=style/SMALLPIC'
				}
				this.$toast.success('图片上传成功')
			}else{
				this.loading = false
				this.$toast.fail(res.msg)
			}
		},
		// 上传反面
		async uploadavatarback(e){
			console.log('11111')
			let formData = new FormData();//通过formdata上传
			formData.append('file', e.file)
			const {data:res} = await this.$http.post('/api/alioss/upload',formData)
			if(res.code == 1){
				this.loading = false
				this.form.back_photo = res.data.res.url
				this.back_photo = res.data.res.fullurl + '?x-oss-process=style/SMALLPIC'
				this.$toast.success('图片上传成功')
			}else{
				this.loading = false
				this.$toast.fail(res.msg)
			}
		},
		// 协议详情
		async getAgreementInfo(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_treaty_info', {
				number: this.form.number
			})
			if (result.code == 1) {
				if (result.data.res.front_photo_images) {
					this.form.front_photo = result.data.res.front_photo_images
					this.front_photo = this.serverUrl(result.data.res.front_photo_images)
				}
				if (result.data.res.back_photo_images) {
					this.form.back_photo = result.data.res.back_photo_images
					this.back_photo = this.serverUrl(result.data.res.back_photo_images)
				}
			}
		},
		
		// 保存
		async handSave(e) {
			if(this.loading) return ''
			const {data: result} = await this.$http.post('/api/scsj/upload_scsj_treaty_photo', this.form)
			if (result.code == 1) {
				this.$toast.success('保存成功')
				this.$router.replace({
					path: `/trial-agreement?number=${this.form.number}`
				})
			} else {
				this.$toast.fail(result.msg)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.trialdrive__init{
	padding: 28px 152px;
	text-align: center;
	font-size: 28px;
	font-family: HYQiHeiY3-45;
	color: #9B9B9B;
	line-height: 36px;
}
.front{
	margin: 0 auto 40px;
	width: 686px;
	height: 398px;
	background: url(https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/bg_front.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
	&__con{
		width: 686px;
		height: 398px;
		position: relative;
		text-align: center;
		&__img{
			height: 398px;
			background: #222;
			border-radius: 20px;
		}
		&__edit{
			width: 200px;
			height: 200px;
			position: absolute;
			top: 99px;
			left: 243px;
			background: rgba(0, 0, 0, 0.2);
			border-radius: 10px;
			text-align: center;
			font-size: 20px;
			font-family: HYQiHeiY3-65;
			color: #FFFFFF;
			line-height: 40px;
			padding: 50px 0;
			box-sizing: border-box;
			img{
				width: 40px;
				height: 40px;
				margin-top: 20px;
			}
		}
	}
	&__btn{
		text-align: center;
		font-size: 36px;
		font-family: HYQiHeiY3-65;
		color: #FFFFFF;
		line-height: 40px;
		img{
			width: 120px;
			height: 120px;
			margin-bottom: 24px;
		}
	}
}
.back{
	margin: 0 auto;
	width: 686px;
	height: 398px;
	background: url(https://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/bg_back.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
	&__con{
		width: 686px;
		height: 398px;
		position: relative;
		text-align: center;
		&__img{
			height: 398px;
			background: #222;
			border-radius: 20px;
		}
		&__edit{
			width: 200px;
			height: 200px;
			position: absolute;
			top: 99px;
			left: 243px;
			background: rgba(0, 0, 0, 0.2);
			border-radius: 10px;
			text-align: center;
			font-size: 20px;
			font-family: HYQiHeiY3-65;
			color: #FFFFFF;
			line-height: 40px;
			padding: 50px 0;
			box-sizing: border-box;
			img{
				width: 40px;
				height: 40px;
				margin-top: 20px;
			}
		}
	}
	&__btn{
		text-align: center;
		font-size: 36px;
		font-family: HYQiHeiY3-65;
		color: #FFFFFF;
		line-height: 40px;
		img{
			width: 120px;
			height: 120px;
			margin-bottom: 24px;
		}
	}
}
.savebtn{
	background: #191919;
	position: fixed;
	bottom: 0;
	padding: 22px 40px;
	box-sizing: border-box;
	padding-bottom: calc(constant(safe-area-inset-bottom) + 22px);
	padding-bottom: calc(env(safe-area-inset-bottom) + 22px);
	width: 100%;
	div{
		width: 100%;
		background: #FA0037;
		text-align: center;
		line-height: 92px;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
	}
}

.loading{
	position: fixed;
	left: calc(50% - 100px) ;
	top: calc(50% - 100px);
	width: 160px;
	padding: 20px;
	height: 160px;
	display: flex;
	align-items: center;
	background-color: #191919;
}
</style>
